import React from 'react';

const LimeFlightSection1 = (props) => {
    console.log(props.image11x);

    return (
        <section className='section-1'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="image-block-1">
                            <img
                                src={props.image11x?.sourceUrl}
                                srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                width={props.image11x?.width}
                                height="100%"
                                alt={props.image11x?.altText}
                            />  
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="image-block-1">
                            <img
                                src={props.image21x?.sourceUrl}
                                srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                width={props.image21x?.width}
                                height="100%"
                                alt={props.image21x?.altText}
                            />  
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default LimeFlightSection1;