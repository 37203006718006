import * as React from "react"

const LimeFlightSection9 = (props) => {
    return (
        <section className="section-9">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-18">
                        <div className="title-and-description">
                            <h2 className="h1 section-title">{props.mainTitle}</h2>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
                <div className="image-block-wrapper">
                    <div className="row">
                        <div className="col-lg-24">
                            <div className="image-block">
                                <img
                                    src={props.image11x?.sourceUrl}
                                    srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                    width={props.image11x?.width}
                                    height="100%"
                                    alt={props.image11x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="image-block">
                                <img
                                    src={props.image21x?.sourceUrl}
                                    srcSet={props.image22x?.sourceUrl + " 2x, " + props.image21x?.sourceUrl + " 1x"}
                                    width={props.image21x?.width}
                                    height="100%"
                                    alt={props.image21x?.altText}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="image-block">
                                <img
                                    src={props.image31x?.sourceUrl}
                                    srcSet={props.image32x?.sourceUrl + " 2x, " + props.image31x?.sourceUrl + " 1x"}
                                    width={props.image31x?.width}
                                    height="100%"
                                    alt={props.image31x?.altText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default LimeFlightSection9;