import React from 'react';
import shortid from  "shortid";

const LimeFlightSection3 = (props) => {

    return (
        <section className="section-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-18">
                        <div className="title-and-description">
                            <h2 className="h1 section-title">{props.mainTitle}</h2>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
            <div className="image-block-wrapper">
                <div className="container">
                    <div className="image-list">
                        <div className="image-block-1">
                            <img
                                src={props.image11x?.sourceUrl}
                                srcSet={props.image12x?.sourceUrl + " 2x, " + props.image11x?.sourceUrl + " 1x"}
                                width={props.image11x?.width}
                                height="100%"
                                alt={props.image11x?.altText}
                            /> 
                        </div>
                        <div className="row">
                            {props.imageslist?.map((object, i) => {
                                return (
                                    <div className={((i+2)%5 === 1) ? 'col-lg-24' : 'col-lg-12'} key={shortid.generate()}>
                                        <div className="image-block">
                                            <img
                                                src={object.image1x?.sourceUrl}
                                                srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                width={object.image1x?.width}
                                                height="100%"
                                                alt={object.image1x?.altText}
                                            />
                                        </div>
                                    </div>     
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LimeFlightSection3;