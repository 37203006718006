import * as React from "react"

const LimeFlightSection6 = (props) => {
    return (
        <section className="section-6">
            <div className="container">
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        height="100%"
                        alt={props.image1x?.altText}
                    /> 
                </div>
            </div>
        </section> 
    )
}

export default LimeFlightSection6;