import React, { useRef, useState } from 'react';
import { graphql } from "gatsby"

import parse from 'html-react-parser';
import Layout from "../../components/layout";
import Seo from "../../components/seo";

//sections
import LimeFlightHeroSection from '../sections/caseStudies/limeFlight/LimeFlightHeroSection';
import ChallengesSection from '../sections/caseStudies/ChallengesSection';
import LimeFlightSection1 from '../sections/caseStudies/limeFlight/LimeFlightSection1';
import LimeFlightSection2 from '../sections/caseStudies/limeFlight/LimeFlightSection2';
import LimeFlightSection3 from '../sections/caseStudies/limeFlight/LimeFlightSection3';
import LimeFlightSection4 from '../sections/caseStudies/limeFlight/LimeFlightSection4';
import LimeFlightSection5 from '../sections/caseStudies/limeFlight/LimeFlightSection5';
import LimeFlightSection6 from '../sections/caseStudies/limeFlight/LimeFlightSection6';
import LimeFlightSection7 from '../sections/caseStudies/limeFlight/LimeFlightSection7';
import LimeFlightSection8 from '../sections/caseStudies/limeFlight/LimeFlightSection8';
import LimeFlightSection9 from '../sections/caseStudies/limeFlight/LimeFlightSection9';
import LimeFlightSection10 from '../sections/caseStudies/limeFlight/LimeFlightSection10';
import LimeFlightSection11 from '../sections/caseStudies/limeFlight/LimeFlightSection11';
import LimeFlightSection12 from '../sections/caseStudies/limeFlight/LimeFlightSection12';
import LimeFlightSection13 from '../sections/caseStudies/limeFlight/LimeFlightSection13';

const LimeFlight = ({data}) => {
    const mainData = data.allWpPage.nodes[0].caseStudyLimeFlightPageACF;
    const sectionRef = useRef();
    const [bgTransition, setBgTransition] = useState('');
  return(
      <Layout headerClass="white-header">
          <Seo title="LimeFlight" />
          <div className={"limeflight-case-study page-wrapper " + bgTransition} id="limeflight-casestudy-page-wrapper" ref={sectionRef}>
            <LimeFlightHeroSection 
                preTitle={parse(mainData.limeflightHeroSectionPretitle)}
                mainTitle={parse(mainData.limeflightHeroSectionTitle)}
                image1x={
                    {
                      sourceUrl: mainData.limeflightHeroSectionImage1x.sourceUrl,
                      altText: mainData.limeflightHeroSectionImage1x.altText,
                      width: mainData.limeflightHeroSectionImage1x.width,
                    }
                }
                image2x={
                  {
                    sourceUrl: mainData.limeflightHeroSectionImage2x.sourceUrl,
                    altText: mainData.limeflightHeroSectionImage2x.altText,
                    width: mainData.limeflightHeroSectionImage2x.width,
                  }
                }
                mobileImage={
                  {
                    sourceUrl: mainData.limeflightHeroSectionMobileImage.sourceUrl,
                    altText: mainData.limeflightHeroSectionMobileImage.altText,
                    width: mainData.limeflightHeroSectionMobileImage.width,
                  }
                }
            />
            <ChallengesSection 
                companyInfoList={mainData.limeflightChallengeSectionInfoList}
                challengesList={mainData.limeflightChallengeSectionChallengesList}
            />
            <LimeFlightSection1 
                image11x={
                  {
                    sourceUrl: mainData.limeflightSection1Image11x.sourceUrl,
                    altText: mainData.limeflightSection1Image11x.altText,
                    width: mainData.limeflightSection1Image11x.width,
                  }
                }
                image12x={
                  {
                    sourceUrl: mainData.limeflightSection1Image12x.sourceUrl,
                    altText: mainData.limeflightSection1Image12x.altText,
                    width: mainData.limeflightSection1Image12x.width,
                  }
                }
                image21x={
                  {
                    sourceUrl: mainData.limeflightSection1Image21x.sourceUrl,
                    altText: mainData.limeflightSection1Image21x.altText,
                    width: mainData.limeflightSection1Image21x.width,
                  }
                }
                image22x={
                  {
                    sourceUrl: mainData.limeflightSection1Image22x.sourceUrl,
                    altText: mainData.limeflightSection1Image22x.altText,
                    width: mainData.limeflightSection1Image22x.width,
                  }
                }
            />
            <LimeFlightSection2 
              preTitle={parse(mainData.limeflightSection2PreTitle)}
              mainTitle={parse(mainData.limeflightSection2Title)}
              mainDescription={parse(mainData.limeflightSection2Description)}
              image1x={
                {
                  sourceUrl: mainData.limeflightSection2Image1x.sourceUrl,
                  altText: mainData.limeflightSection2Image1x.altText,
                  width: mainData.limeflightSection2Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection2Image2x.sourceUrl,
                  altText: mainData.limeflightSection2Image2x.altText,
                  width: mainData.limeflightSection2Image2x.width,
                }
              }
            />
            <LimeFlightSection3
              mainTitle={parse(mainData.limeflightSection3Title)}
              mainDescription={parse(mainData.limeflightSection3Description)}
              image11x={
                {
                  sourceUrl: mainData.limeflightSection3Image11x.sourceUrl,
                  altText: mainData.limeflightSection3Image11x.altText,
                  width: mainData.limeflightSection3Image11x.width,
                }
              }
              image12x={
                {
                  sourceUrl: mainData.limeflightSection3Image12x.sourceUrl,
                  altText: mainData.limeflightSection3Image12x.altText,
                  width: mainData.limeflightSection3Image12x.width,
                }
              }
              imageslist={mainData.limeflightSection3ImagesList}
            />
            <LimeFlightSection4
                imageslist={mainData.limeflightSection4ImageList}
            />
            <LimeFlightSection5 
              mainTitle={parse(mainData.limeflightSection5Title)}
              mainDescription={parse(mainData.limeflightSection5Description)}
              image1x={
                {
                  sourceUrl: mainData.limeflightSection5Image1x.sourceUrl,
                  altText: mainData.limeflightSection5Image1x.altText,
                  width: mainData.limeflightSection5Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection5Image2x.sourceUrl,
                  altText: mainData.limeflightSection5Image2x.altText,
                  width: mainData.limeflightSection5Image2x.width,
                }
              }
            />
            <LimeFlightSection6 
              image1x={
                {
                  sourceUrl: mainData.limeflightSection6Image1x.sourceUrl,
                  altText: mainData.limeflightSection6Image1x.altText,
                  width: mainData.limeflightSection6Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection6Image2x.sourceUrl,
                  altText: mainData.limeflightSection6Image2x.altText,
                  width: mainData.limeflightSection6Image2x.width,
                }
              }
            />
            <LimeFlightSection7 
              mainTitle={parse(mainData.limeflightSection7Title)}
              mainDescription={parse(mainData.limeflightSection7Description)}
              image11x={
                {
                  sourceUrl: mainData.limeflightSection7Image11x.sourceUrl,
                  altText: mainData.limeflightSection7Image11x.altText,
                  width: mainData.limeflightSection7Image11x.width,
                }
              }
              image12x={
                {
                  sourceUrl: mainData.limeflightSection7Image12x.sourceUrl,
                  altText: mainData.limeflightSection7Image12x.altText,
                  width: mainData.limeflightSection7Image12x.width,
                }
              }
              image21x={
                {
                  sourceUrl: mainData.limeflightSection7Image21x.sourceUrl,
                  altText: mainData.limeflightSection7Image21x.altText,
                  width: mainData.limeflightSection7Image21x.width,
                }
              }
              image22x={
                {
                  sourceUrl: mainData.limeflightSection7Image22x.sourceUrl,
                  altText: mainData.limeflightSection7Image22x.altText,
                  width: mainData.limeflightSection7Image22x.width,
                }
              }
              image31x={
                {
                  sourceUrl: mainData.limeflightSection7Image31x.sourceUrl,
                  altText: mainData.limeflightSection7Image31x.altText,
                  width: mainData.limeflightSection7Image31x.width,
                }
              }
              image32x={
                {
                  sourceUrl: mainData.limeflightSection7Image32x.sourceUrl,
                  altText: mainData.limeflightSection7Image32x.altText,
                  width: mainData.limeflightSection7Image32x.width,
                }
              }
            />
            <LimeFlightSection8 
              mainTitle={parse(mainData.limeflightSection8Title)}
              mainDescription={parse(mainData.limeflightSection8Description)}
              image1x={
                {
                  sourceUrl: mainData.limeflightSection8Image1x.sourceUrl,
                  altText: mainData.limeflightSection8Image1x.altText,
                  width: mainData.limeflightSection8Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection8Image2x.sourceUrl,
                  altText: mainData.limeflightSection8Image2x.altText,
                  width: mainData.limeflightSection8Image2x.width,
                }
              }
            />
            <LimeFlightSection9
              mainTitle={parse(mainData.limeflightSection9Title)}
              mainDescription={parse(mainData.limeflightSection9Description)}
              image11x={
                {
                  sourceUrl: mainData.limeflightSection9Image11x.sourceUrl,
                  altText: mainData.limeflightSection9Image11x.altText,
                  width: mainData.limeflightSection9Image11x.width,
                }
              }
              image12x={
                {
                  sourceUrl: mainData.limeflightSection9Image12x.sourceUrl,
                  altText: mainData.limeflightSection9Image12x.altText,
                  width: mainData.limeflightSection9Image12x.width,
                }
              }
              image21x={
                {
                  sourceUrl: mainData.limeflightSection9Image21x.sourceUrl,
                  altText: mainData.limeflightSection9Image21x.altText,
                  width: mainData.limeflightSection9Image21x.width,
                }
              }
              image22x={
                {
                  sourceUrl: mainData.limeflightSection9Image22x.sourceUrl,
                  altText: mainData.limeflightSection9Image22x.altText,
                  width: mainData.limeflightSection9Image22x.width,
                }
              }
              image31x={
                {
                  sourceUrl: mainData.limeflightSection9Image31x.sourceUrl,
                  altText: mainData.limeflightSection9Image31x.altText,
                  width: mainData.limeflightSection9Image31x.width,
                }
              }
              image32x={
                {
                  sourceUrl: mainData.limeflightSection9Image32x.sourceUrl,
                  altText: mainData.limeflightSection9Image32x.altText,
                  width: mainData.limeflightSection9Image32x.width,
                }
              }
            />
            <LimeFlightSection10 
              mainTitle={parse(mainData.limeflightSection10Title)}
              mainDescription={parse(mainData.limeflightSection10Description)}
              image1x={
                {
                  sourceUrl: mainData.limeflightSection10Image1x.sourceUrl,
                  altText: mainData.limeflightSection10Image1x.altText,
                  width: mainData.limeflightSection10Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection10Image2x.sourceUrl,
                  altText: mainData.limeflightSection10Image2x.altText,
                  width: mainData.limeflightSection10Image2x.width,
                }
              }
            /> 
            <LimeFlightSection11 
              mainTitle={parse(mainData.limeflightSection11Title)}
              image1x={
                {
                  sourceUrl: mainData.limeflightSection11Image1x.sourceUrl,
                  altText: mainData.limeflightSection11Image1x.altText,
                  width: mainData.limeflightSection11Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection11Image2x.sourceUrl,
                  altText: mainData.limeflightSection11Image2x.altText,
                  width: mainData.limeflightSection11Image2x.width,
                }
              }
            />
            <LimeFlightSection12 
              image1x={
                {
                  sourceUrl: mainData.limeflightSection12Image1x.sourceUrl,
                  altText: mainData.limeflightSection12Image1x.altText,
                  width: mainData.limeflightSection12Image1x.width,
                }
              }
              image2x={
                {
                  sourceUrl: mainData.limeflightSection12Image2x.sourceUrl,
                  altText: mainData.limeflightSection12Image2x.altText,
                  width: mainData.limeflightSection12Image2x.width,
                }
              }
            />
            <LimeFlightSection13
              mainTitle={parse(mainData.limeflightSection13Title)}
              mainDescription={parse(mainData.limeflightSection13Description)}
              url={parse(mainData.limeflightSection13Url)}
              imageslist={mainData.limeflightSection13ImageList}
            />
          </div>
      </Layout>
  )
}

export const LimeFlightQuery = graphql`
{
    allWpPage(filter: {slug: {eq: "limeflight"}}) {
        nodes {
          caseStudyLimeFlightPageACF {
            limeflightHeroSectionPretitle
            limeflightHeroSectionTitle
            limeflightHeroSectionImage1x {
              altText
              sourceUrl
              width
            }
            limeflightHeroSectionImage2x {
              altText
              sourceUrl
              width
            }
            limeflightHeroSectionMobileImage {
              altText
              sourceUrl
              width
            }
            limeflightChallengeSectionInfoList {
                title
                description
            }
            limeflightChallengeSectionChallengesList {
              title
              description
            }           
            limeflightSection1Image11x {
              altText
              sourceUrl
              width
            }
            limeflightSection1Image12x {
              altText
              sourceUrl
              width
            }
            limeflightSection1Image21x {
              altText
              sourceUrl
              width
            }
            limeflightSection1Image22x {
              altText
              sourceUrl
              width
            }
            limeflightSection2PreTitle
            limeflightSection2Title
            limeflightSection2Description
            limeflightSection2Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection2Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection3Title
            limeflightSection3Description
            limeflightSection3Image11x {
              altText
              sourceUrl
              width
            }
            limeflightSection3Image12x {
              altText
              sourceUrl
              width
            }
            limeflightSection3ImagesList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            limeflightSection4ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            limeflightSection5Title
            limeflightSection5Description
            limeflightSection5Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection5Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection6Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection6Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection7Title
            limeflightSection7Description
            limeflightSection7Image11x {
              altText
              sourceUrl
              width
            }
            limeflightSection7Image12x {
              altText
              sourceUrl
              width
            }
            limeflightSection7Image21x {
              altText
              sourceUrl
              width
            }
            limeflightSection7Image22x {
              altText
              sourceUrl
              width
            }
            limeflightSection7Image31x {
              altText
              sourceUrl
              width
            }
            limeflightSection7Image32x {
              altText
              sourceUrl
              width
            }
            limeflightSection8Title
            limeflightSection8Description
            limeflightSection8Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection8Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection9Title
            limeflightSection9Description
            limeflightSection9Image11x {
              altText
              sourceUrl
              width
            }
            limeflightSection9Image12x {
              altText
              sourceUrl
              width
            }
            limeflightSection9Image21x {
              altText
              sourceUrl
              width
            }
            limeflightSection9Image22x {
              altText
              sourceUrl
              width
            }
            limeflightSection9Image31x {
              altText
              sourceUrl
              width
            }
            limeflightSection9Image32x {
              altText
              sourceUrl
              width
            }
            limeflightSection10Title
            limeflightSection10Description
            limeflightSection10Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection10Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection11Title
            limeflightSection11Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection11Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection12Image1x {
              altText
              sourceUrl
              width
            }
            limeflightSection12Image2x {
              altText
              sourceUrl
              width
            }
            limeflightSection13Title
            limeflightSection13Description
            limeflightSection13Url
            limeflightSection13ImageList {
              image1x {
                altText
                sourceUrl
                width
              }
              image2x {
                altText
                sourceUrl
                width
              }
            }
            limeflightTestimonialSectionTitle
            limeflightTestimonialSectionDescription
            limeflightTestimonialSectionName
            limeflightTestimonialSectionDesignation
            limeflightTestimonialSectionHeadshot1x {
              altText
              sourceUrl
              width
            }
            limeflightTestimonialSectionHeadshot2x {
              altText
              sourceUrl
              width
            }
            limeflightTestimonialSectionLogo {
              altText
              sourceUrl
              width
            }
          }
        }
    }
}
`

export default LimeFlight;