import React from 'react';
import shortid from  "shortid";
import { Link } from "gatsby";

const LimeFlightSection13 = (props) => {

    return (
        <section className="section-13">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-18">
                        <div className="title-and-description">
                            <h2 className="h1 section-title normal-spacing">{props.mainTitle}</h2>
                            <p className="section-description normal-spacing">{props.mainDescription}</p>
                            <Link className="url" to="#">View Website Case Study</Link>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
                <div className="image-items">
                    {props.imageslist?.map((object, i) => {
                        return (
                            <div className="item" key={shortid.generate()}>
                                <img
                                    src={object.image1x?.sourceUrl}
                                    srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                    width={object.image1x?.width}
                                    height="100%"
                                    alt={object.image1x?.altText}
                                />
                            </div>     
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default LimeFlightSection13;