import React from 'react';

const LimeFlightSection5 = (props) => {

    return (
        <section className="section-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-18">
                        <div className="title-and-description">
                            <h2 className="h1 section-title">{props.mainTitle}</h2>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
            <div className="image-block-wrapper">
                <div className="container">
                    <div className="image-block">
                        <img
                            src={props.image1x?.sourceUrl}
                            srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                            width={props.image1x?.width}
                            height="100%"
                            alt={props.image1x?.altText}
                        /> 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LimeFlightSection5;