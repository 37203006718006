import * as React from "react"
import shortid from  "shortid";

const LimeFlightSection2 = (props) => {
    return (
        <section className="section-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-18">
                        <div className="title-and-description">
                            <h2 className="h2 section-pre-title">{props.preTitle}</h2>
                            <h2 className="h1 section-title">{props.mainTitle}</h2>
                            <p className="section-description">{props.mainDescription}</p>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
                <div className="image-block">
                    <img
                        src={props.image1x?.sourceUrl}
                        srcSet={props.image2x?.sourceUrl + " 2x, " + props.image1x?.sourceUrl + " 1x"}
                        width={props.image1x?.width}
                        height="100%"
                        alt={props.image1x?.altText}
                    /> 
                </div>
            </div>
        </section> 
    )
}

export default LimeFlightSection2;