import * as React from "react"
const LimeFlightSection12 = (props) => {
    return (
        <section className="section-12">
            <div className="container">
                <div className="image-block">
                    <img
                        src="https://backend.decojent.com/wp-content/uploads/2024/01/1000screens.png"
                        alt="1000 screen of limeflight"
                        width={100}
                        height="100%"
                    />
                    {/* <video src="https://backend.decojent.com/wp-content/uploads/2024/01/1000-screens.mp4" muted autoPlay loop style={{width: "100%", display: "block"}}></video> */}
                </div>
            </div>
        </section> 
    )
}

export default LimeFlightSection12;